import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components';
import { ProjectPreview } from '../components/common';
import { Categories } from '../components/Projects';
import './projects.scss';

const Projects = (props) => {
  const {
    data: {
      site: {
        siteMetadata,
      },
      tags: {
        edges: tagData,
      },
      page: {
        data: pageData,
      },
      projects: {
        nodes: projectsData,
      },
    },
    location,
  } = props;
  const {
    projectSlug,
    categorySlug,
  } = siteMetadata;
  const projectTags = tagData.map(item => item.node.uid);
  console.log({ projectTags });
  const {
    metaTitle,
    metaDescription,
    openGraphImage,
  } = pageData;
  const seoData = {
    metaTitle,
    metaDescription,
    openGraphImage,
  };
  return (
    <Layout
      location={location}
      seoData={seoData}
    >
      <section className="project-page">
        <Categories
          location={location}
          categories={projectTags}
          projectSlug={projectSlug}
          categorySlug={categorySlug}
        />
        <div className="projects">
          {projectsData && projectsData.map(project => (
            <ProjectPreview
              key={project.uid}
              projectSlug={projectSlug}
              uid={project.uid}
              {...project.data}
            />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default Projects;

export const pageQuery = graphql`
query ProjectsCategoryQuery($tagSlug: String!) {
  site {
    siteMetadata {
      projectSlug,
      categorySlug,
    }
  },
  page: prismicProjectHome {
    uid
    data {
      metaTitle: meta_title {
        html
        text
      },
      metaDescription: meta_description {
        html
        text
      },
      openGraphImage: open_graph_image {
        alt
        copyright
        url
      }
    }
  }
  tags: allPrismicProjectTag {
    edges {
      node {
        uid
      }
    }
  }
  projects: allPrismicProject(
    filter: {
      data: {
        projects_tags: {
          elemMatch: {
            project_tag: {
              uid: { in: [$tagSlug] }
            }
          }
        }
      }
    }
    sort: {
      fields: [data___project_completion], order: DESC
    }
  ) {
    nodes {
      uid
      data {
        client {
          text
        }
        projectName: project_name {
          text
        }
       featuredImage: featured_image {
         fluid(maxWidth: 1000) {
           ...GatsbyPrismicImageFluid
         }
         url
         alt
        }
        openingDescription: opening_description {
          html
          text
        }
        projectTags: projects_tags {
          projectTag: project_tag {
            uid
          }
        }
      }
    }
  }
}
`;
