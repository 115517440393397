import React from 'react';
import { Link } from 'gatsby';
import './Categories.scss';

const Categories = (props) => {
  const {
    location,
    categories,
    categorySlug,
    projectSlug,
  } = props;
  const { pathname: currentPath } = location;
  const isProjectsHome = Boolean(`/${projectSlug}` === currentPath);
  return (
    <div className="categories-menu">
      <ul className="nav-items">
        <li className={`nav-item ${isProjectsHome ? 'active' : ''}`}>
          <Link
            to={`/${projectSlug}`}
            aria-label="See all projects"
          >
            <span>All</span>
          </Link>
        </li>
        {categories.map((category) => {
          const isActive = Boolean(`/${projectSlug}/${categorySlug}/${category}` === currentPath);
          const categoryTitle = category;
          return (
            <li key={category} className={`nav-item ${isActive ? 'active' : ''}`}>
              <Link
                to={`/${projectSlug}/${categorySlug}/${category}`}
                aria-label={`See projects marked as ${categoryTitle}`}
              >
                <span>{categoryTitle}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Categories;
